class ContactButton {
  constructor (options = {}) {
    this.element = options.element
  }

  init () {
    window.addEventListener('scroll', () => {
      const windowHeight = window.innerHeight - 20
      if (window.scrollY >= windowHeight) {
        this.element.classList.add('shown')
      } else {
        this.element.classList.remove('shown')
      }
    })
  }
}

export default ContactButton
