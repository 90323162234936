import 'waypoints/lib/noframework.waypoints.js'
import arrayFrom from 'array-from'

class Menu {
  constructor (options = {}) {
    this.element = options.element
    this.sections = arrayFrom(document.querySelectorAll('article'))
    this.links = document.querySelectorAll('.menu-anchor__wrapper a')
    this.navigation = document.querySelector('header')
    this.menuToggle = this.element.querySelector('.menu__toggle')
    // this.navHeight = this.navigation.getBoundingClientRect().height
  }

  init () {
    this.changeLinkState()
    this.menuToggle.addEventListener('click', (event) => {
      this.toggleMobileMenu(event.target)
    })
    arrayFrom(this.links).forEach((link) => {
      link.addEventListener('click', () => {
        this.menuToggle.classList.toggle('active')
      })
    })
  }

  changeLinkState () {
    arrayFrom(this.sections).forEach((section) => {
      const element = document.getElementById(section.id)
      const waypoint = new Waypoint({
        element: element,
        handler: () => {
          console.log('Basic waypoint triggered', waypoint)
          this.checkForNavElement(element)
        }
      })
    })
  }

  checkForNavElement (element) {
    const sectionAnchor = element.querySelector('.section-anchor').id
    const navEntry = this.navigation.querySelector(`[data-anchor="${sectionAnchor}"]`)
    if (navEntry !== null) {
      this.links.forEach((link) => {
        link.classList.remove('active')
      })
      navEntry.classList.add('active')
    }
  }

  toggleMobileMenu (target) {
    this.menuToggle.classList.toggle('active')
  }
}

export default Menu
