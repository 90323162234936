import arrayFrom from 'array-from'

/* import Classes */
import Menu from '../src/Menu/index.js'
import Article from '../src/Article/index.js'
import Header from '../src/Header/index.js'
import ContactButton from '../src/ContactButton/index.js'
import Slider from '../www/site/modules/gallery/gallery.js'

/* define Classes */
const defineClassesMaster = () => ({
  Menu,
  Article,
  Slider,
  Header,
  ContactButton
})

const getModuleForInit = () => {
  const classesMaster = defineClassesMaster()
  return Object.assign({}, classesMaster)
}

const initModules = () => {
  const classes = getModuleForInit()

  const moduleElements = document.querySelectorAll('[data-module]')

  window.initializedModuleInstances = arrayFrom(moduleElements).map((moduleElement) => {
    const moduleClassName = moduleElement.getAttribute('data-module')

    try {
      if (moduleClassName !== '') {
        const instance = new (classes[moduleClassName])({
          element: moduleElement
        })
        instance.init()
        return instance
      }
      return null
    } catch (er) {
      console.error(`error initializing module instance with class "${moduleClassName}`)
      console.error(er)
      return null
    }
  })
}
document.addEventListener('DOMContentLoaded', () => {
  initModules()
})

