import Glide from '@glidejs/glide'

class Slider {
  constructor (options = {}) {
    this.element = options.element
  }

  init () {
    const glide = new Glide(this.element, {
      type: 'slider',
      perView: 1,
      focusAt: 'center',
      startAt: 0,
      gap: 0,
      arrows: true,
      navigation: true,
      autoplay: 5000
    })
    glide.mount()
  }
}

export default Slider
