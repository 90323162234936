
class Section {
  constructor (options = {}) {
    this.element = options.element
  }

  init () {
    // this.setBackgroundColor()
  }

  setBackgroundColor () {
    const attribute = this.element.getAttribute('data-background-color')
    this.element.style.background = `"${attribute}"`
  }
}

export default Section
